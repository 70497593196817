import React from "react";
import { withRouter } from "react-router-dom";
import { BeatLoader } from "react-spinners";

const DEBUG = true;

function Results({ match, data, loading }) {
  function renderRows() {
    return data.map((row, i) => {
      return (
        <tr key={`${row.id}-${i}`}>
          <td>
            {row.product_id}
          </td>
          <td>
            <a href={`/${match.params.account_id}/products/${row.product_id}`} class='underlined-hover'>
              {row.product_name}
            </a>
          </td>
          <td>
            {row.part_name}
          </td>
          <td><a href={`/${match.params.account_id}/rfid_tags/${row.rfid_tag_id}/edit`} class='underlined-hover'>{row.rfid_tag_number}</a></td>
          <td>{row.purchase_date}</td>
          <td>{row.last_used_at}</td>
          <td style={redIfZero(row.product_purchase_price)}>
            {row.product_purchase_price}
          </td>

          <td style={redIfZero(row.months_staged)}>{Number(row.months_staged)}</td>
          <td style={redIfZero(row.months_active)}>{Number(row.months_active)}</td>
          <td style={redIfZero(row.months_staged / row.months_active)}>{Number(row.months_staged / row.months_active * 100).toFixed(2)}</td>
          <td style={redIfZero(row.product_times_used)}>
            {row.product_times_used}
          </td>

          <td style={redIfZero(row.roi)}>{Number(row.roi).toFixed(2)}</td>


          <td style={redIfZero(row.product_retail_value)}>
            {row.product_retail_value}
          </td>

          <td style={redIfZero(row.product_sale_price)}>
            {row.product_sale_price}
          </td>

          <td style={redIfZero(row.product_rental_price)}>
            {row.product_rental_price}
          </td>

          <td>{row.current_location}</td>
          <td style={redIfZero(row.msrp)}>{row.msrp}</td>
          <td>{row.vendor_name}</td>

        </tr>
      );
    });
  }
  function redIfZero(val) {
    return {
      color: Number(val) === 0 ? "red" : "black"
    };
  }
  return (
    <table className="table table-striped" id="productsReportTable">
      <thead>
        <tr>
          <th style={{ whiteSpace: "nowrap" }}>Product ID</th>
          <th style={{ whiteSpace: "nowrap" }}>Product Name</th>
          <th style={{ whiteSpace: "nowrap" }}>Part Name</th>
          <th style={{ whiteSpace: "nowrap" }}>Tag #</th>
          <th style={{ whiteSpace: "nowrap" }}>Purchase Date</th>
          <th style={{ whiteSpace: "nowrap" }}>Last Used</th>
          <th style={{ whiteSpace: "nowrap" }}>Purchase</th>
          <th style={{ whiteSpace: "nowrap" }}>Months staged</th>
          <th style={{ whiteSpace: "nowrap" }}>Months active</th>
          <th style={{ whiteSpace: "nowrap" }}>% of use</th>
          <th style={{ whiteSpace: "nowrap" }}># Times Used</th>
          <th style={{ whiteSpace: "nowrap" }}>ROI</th>
          <th style={{ whiteSpace: "nowrap" }}>Retail</th>
          <th style={{ whiteSpace: "nowrap" }}>Sale</th>
          <th style={{ whiteSpace: "nowrap" }}>Rental</th>
          <th style={{ whiteSpace: "nowrap" }}>Current Location</th>
          <th style={{ whiteSpace: "nowrap" }}>MSRP</th>
          <th style={{ whiteSpace: "nowrap" }}>Vendor Name</th>

        </tr>
      </thead>

      <tbody>
        {loading ? (
          <tr>
            <td colSpan={9}>
              <center>
                <BeatLoader />
              </center>
            </td>
          </tr>
        ) : data.length === 0 ? (
          <tr>
            <td colSpan={5}>
              <b>No products match your filters, try resetting them</b>
            </td>
          </tr>
        ) : (
          renderRows()
        )}
      </tbody>
    </table>
  );
}

export default withRouter(Results);
