import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from '../components/app'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import "@hotwired/turbo-rails"
import "channels"
import * as ActiveStorage from "@rails/activestorage"
import "trix"
import "@rails/actiontext"


// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


const application = Application.start()
const context = require.context('controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
ActiveStorage.start()

$(window).on('turbo:load', function (event) {
  var root = document.getElementById('react-root')

  if (root) {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      root,
    )
  }

})

document.addEventListener("turbo:frame-missing", (event) => {
  if (event.detail.response.status == 500) {
    const { response } = event.detail;
    response.text().then((html) => {
      document.body.innerHTML = html;
    });
    event.preventDefault();
  }
});

// direct_uploads.js
addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})

document.addEventListener('turbo:submit-start', (event) => {
  const submitter = event.detail.formSubmission.submitter
  if (submitter) {
    submitter.toggleAttribute('disabled', true)
    submitter.classList.add('turbo-submit-disabled')
  }
})