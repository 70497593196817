import React from "react";
import formatCurrency from "format-currency";

export default function Totals({ totals, loading }) {
  return (
    <div>
      <h2>Totals</h2>
      <div>
        Products: {!loading && totals ? parseInt(totals.product_count) : ""}
      </div>
      <div>
        Tags: {!loading && totals ? parseInt(totals.rfid_tag_count) : ""}
      </div>
      <div>
        Retail:{" "}
        {!loading && totals
          ? formatCurrency(totals.working_value_sum)
          : ""}
      </div>
      <div>
        Purchase:{" "}
        {!loading && totals
          ? formatCurrency(totals.purchase_price_sum)
          : ""}
      </div>
      <div>
        Rental:{" "}
        {!loading && totals
          ? formatCurrency(totals.rental_price_sum)
          : ""}
      </div>
      <div>
        Sale:{" "}
        {!loading && totals ? formatCurrency(totals.sale_price_sum) : ""}
      </div>
    </div>
  );
}
